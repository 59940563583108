<template>
  <modal v-loading="loaders.modal" :show.sync="$store.state.modals.addVideo">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Add Video</h1>
    <validation-observer ref="addVideoForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input
          type="text"
          :rules="{required: true}"
          v-model="request.videoForm.title"
          name="title"
          label="Title*"
          placeholder="Title">
        </base-input>
        <base-input name="type" label="Category">
          <el-select
            class="w-100"
            label="Category"
            v-model="request.videoForm.our_video_category_id"
            @change="onChangeCategory"
          >
            <el-option
              v-for="option in categories"
              class="select-danger"
              :value="option.id"
              :label="option.name"
              :key="option.id + option.name">
            </el-option>
          </el-select>
        </base-input>
        <base-input name="type" label="Sub Category">
          <el-select
            class="w-100"
            label="Category"
            v-model="request.videoForm.our_video_sub_category_id"
          >
            <el-option
              v-for="option in response.subCategories"
              class="select-danger"
              :value="option.id"
              :label="option.name"
              :key="option.id + option.name">
            </el-option>
          </el-select>
        </base-input>
        <base-input name="type" label="Type*" :rules="{required: true}">
          <el-select
            class="w-100"
            label="Type*"
            @change="request.videoForm.url = ''"
            v-model="request.videoForm.url_type">
            <el-option
              v-for="option in dropdowns.type"
              class="select-danger"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>
        <base-input v-if="request.videoForm.url_type === 0"
          type="url"
          :rules="{required: true}"
          v-model="request.videoForm.url"
          name="url"
          label="Video Url*"
          placeholder="Video Url">
        </base-input>
        <div v-else class="mb-3">
          <label class="form-control-label">Video Url*</label>
          <textarea id="textArea"
                    v-model="request.videoForm.url"
                    class="w-100 text-area-input"
                    placeholder="Video Url"
                    required>
          </textarea>
        </div>

        <base-input name="status" label="Status*" :rules="{required: true}">
          <el-select
            class="w-100"
            label="Status*"
            v-model="request.videoForm.status">
            <el-option
              v-for="option in dropdowns.status"
              class="select-danger"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>

        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto" @click="cancelForm">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            Save
          </base-button>
        </div>

      </form>
    </validation-observer>
  </modal>
</template>

<script>
export default {
  name: "AddVideoModal",
  props:['menuId', 'categories'],
  data () {
    return {
      loaders: {
        modal: false,
      },
      request: {
        videoForm: {
          title: '',
          url: '',
          url_type: 0,
          status: '',
          category:null,
          our_video_sub_category_id:null,
        }
      },
      dropdowns: {
        status: [
          {
          value: 1,
          label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
        type: [
          {
          value: 0,
          type: 'url',
          label: 'Url'
          },
          {
            value: 1,
            type: 'text',
            label: 'Video',
          },
        ],
      },
      response: {
        subCategories: [],
      }
    }
  },
  methods: {
    cancelForm() {
      this.resetForm()
      this.$store.commit('hideAddVideoModal')
    },

    // Function to store the Fields of Form to database and get the response
    submitForm() {
      let vm = this;
      vm.loaders.modal = true
      axios.post(this.$store.getters.getBaseUrl + '/api/content/our-videos/store', this.request.videoForm)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          this.resetForm()
          this.$emit('updateVideosEvent')
          vm.$notify.success({
            title: 'Success',
            message: 'Video added successfully.'
          })

        })
        .catch((error) => console.error(error.response))
        .finally(() => {
          vm.loaders.modal = false
          this.$store.commit('hideAddVideoModal')
        });
    },

    // This reset the fields of Form
    resetForm(){
      this.request.videoForm.status = ''
      this.request.videoForm.url_type = 0
      this.request.videoForm.title = ''
      this.request.videoForm.url = ''
      this.request.videoForm.our_video_category_id = null
      this.request.videoForm.our_video_sub_category_id = null
      this.$refs.addVideoForm.reset();
    },

    onChangeCategory(category){
      this.request.videoForm.our_video_sub_category_id = null;
      if (category == 0) {
        this.response.subCategories = [];
      }
      else {
        this.fetchSubCategories(category);
      }
    },

    fetchSubCategories(id) {
      if (id) {
        let vm = this;
        vm.loaders.subCategory = true
        let payLoad = { menuId: vm.menuId, categoryId: id}

        vm.$store.dispatch('contentSubCategory/_APIGetAll', payLoad).then(response => {
          vm.response.subCategories = response.data.data;
        }).catch(error => console.error(error.response));
      }
    },
  }
}
</script>

<style scoped>

.text-area-input {
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}
</style>
